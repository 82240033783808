/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { createMeetings } from '../../api';

import Button from '../Button/Button';
import ErrorText from '../ErrorText/ErrorText';
import Spinner from '../Spinner/Spinner';
import Calendar from '../Calendar/Calendar';
import PlatformSelector from '../PlatformSelector/PlatformSelector';
import CreatedMeetings from './CreatedMeetings';

import getCurrentHoursMinutes from './get-current-hours-minutes';
import setHoursMinutes from './set-hours-minutes';
import { getDurationMinutes } from './get-duration-minutes';

import './create-meeting-form.scss';

function TimePicker({
  name, label, value, onChange,
}) {
  return (
    <div className="time-picker">
      <label className="text-base" htmlFor={name}>{label}</label>

      <input
        type="time"
        id={name}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default function CreateMeetingForm({ meetingTypes: selectedMeetingTypes, autofillRest }) {
  const {
    register, formState: { errors }, handleSubmit, getValues, setValue, watch,
  } = useForm({ mode: 'onChange', defaultValues: { date: new Date(), startTime: getCurrentHoursMinutes(), duration: '00:45' } });

  const [meetingData, setMeetingData] = useState({});

  const mutation = useMutation((data) => createMeetings(data), {
    onSuccess: (data) => setMeetingData(data?.data),
  });

  const onSubmit = async ({
    date, startTime, duration, primaryStream,
  }) => {
    const data = {
      startTime: setHoursMinutes(date, startTime),
      durationMinutes: getDurationMinutes(duration),
      primaryStream,
    };
    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    return <CreatedMeetings meetingData={meetingData} />;
  }

  return (
    <div className="meeting-init-container">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="scheduling-container">
          <Calendar onChange={(date) => setValue('date', date)} value={watch('date')} />

          <div className="time-container">
            <TimePicker nmame="start-time" label="Start time" value={watch('startTime')} onChange={(value) => setValue('startTime', value)} />
            <TimePicker nmame="duration" label="Duration" value={watch('duration')} onChange={(value) => setValue('duration', value)} />
          </div>
        </div>

        <div className="platform-container">
          <PlatformSelector
            register={register}
            formErrors={errors}
          />
          <Button type="submit" disabled={mutation.isLoading}>Create</Button>
        </div>
      </form>

      {mutation.isLoading && (
        <div style={{ marginTop: 20 }}>
          <Spinner />
        </div>
      )}

      {mutation.isError && (
        <ErrorText>
          <p>
            {mutation.error.message}
          </p>
          <p>{mutation.data?.message ?? 'Something went wrong. Please try again'}</p>
        </ErrorText>
      )}
    </div>
  );
}
