import React from 'react';

import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import LoginPage from './pages/login';

import CreatePage from './pages/create';
import OverviewPage from './pages/overview';
import HistoryPage from './pages/history';
import SettingsPage from './pages/settings';

import './app.scss';

function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage onLogin={() => navigate('/app/create')} />} />
        <Route path="/app/create" element={<CreatePage />} />
        <Route path="/app/overview" element={<OverviewPage />} />
        <Route path="/app/profile" element={<HistoryPage />} />
        <Route path="/app/settings" element={<SettingsPage />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </div>
  );
}

export default App;
