/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export default function InfoBox() {
  return (
    <div className="prose prose-sm info-box">
      <h2>This is an info box with information</h2>

      <ul>
        <li>Information 1</li>
        <li>Information 2</li>
      </ul>

      <p>Also there's some more information</p>
    </div>
  );
}
