import React from 'react';

import clsx from 'clsx';

import { AiOutlinePlusCircle } from 'react-icons/ai';
import {
  BsCalendar3, BsBoxArrowLeft, BsPerson, BsWrench,
} from 'react-icons/bs';

import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';
import Logo from '../Logo/Logo';

import './page-layout.scss';

export default function PageLayout({
  includeMenu = true, title, pageClassName, children,
}) {
  return (
    <div className={clsx('layout', includeMenu && 'menu-page-layout')}>
      <div className="menu-sidebar">
        {
          includeMenu && (
          <Menu>
            <Logo className="logo" />

            <MenuItem label="Create a meeting" link="/app/create" icon={<AiOutlinePlusCircle />} />
            <MenuItem label="Upcoming meetings" link="/app/overview" icon={<BsCalendar3 />} />
            <MenuItem label="Meeting history" link="/app/profile" icon={<BsPerson />} />
            <MenuItem label="Settings" link="/app/settings" icon={<BsWrench />} />

            <div className="menu-bottom">
              <MenuItem label="Sign out" link="/" icon={<BsBoxArrowLeft />} />
            </div>
          </Menu>
          )
        }
      </div>
      <div className="content">
        <div className={clsx('page', pageClassName)}>
          {title && <h1 className="title">{title}</h1>}
        </div>
        {children}
      </div>
      <div className="right-filler" />
    </div>
  );
}
