import React from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import PageLayout from '../components/Layout/PageLayout';
import Spinner from '../components/Spinner/Spinner';
import MeetingList from '../components/MeetingList/MeetingList';

import { deleteMeeting, getMeetings } from '../api';
import { sortMeetings } from '../util';

export default function OverviewPage() {
  const queryClient = useQueryClient();

  const {
    isLoading,
    data,
  } = useQuery(['overview'], getMeetings, { cacheTime: 0, staleTime: 0 });

  const mutation = useMutation((id) => deleteMeeting(id), {
    onSuccess: () => queryClient.invalidateQueries(['overview']),
  });

  const activeMeetings = sortMeetings(data?.data)?.filter((m) => m.status === 'running' || m.status === 'initializing');
  const scheduledMeetings = sortMeetings(data?.data)?.filter((m) => m.status === 'scheduled');

  return (
    <PageLayout pageClassName="overview-page" title="Meeting overview" center>
      {
          (isLoading || mutation.isLoading)
            ? <Spinner />
            : (
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-6 items-center">
                  <h2>Active meetings</h2>
                  <MeetingList meetings={activeMeetings} onDeleteClick={(id) => { mutation.mutate(id); }} />
                </div>
                <div className="flex flex-col gap-6 items-center">
                  <h2>Scheduled meetings</h2>
                  <MeetingList meetings={scheduledMeetings} onDeleteClick={(id) => { mutation.mutate(id); }} />
                </div>
              </div>
            )
      }
    </PageLayout>
  );
}
