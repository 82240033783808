/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import clsx from 'clsx';

import meetingTypes from '../CreateMeetingForm/meetingTypes';

import { uriToStream } from '../../util';

import './platform-selector.scss';

export default function PlatformSelector({
  register,
  formErrors,
}) {
  const [primaryStreamKey, setPrimaryStreamKey] = useState(null);

  const primaryStreamData = meetingTypes[primaryStreamKey];

  return (
    <div className="platform-selector">
      <div className="input-container">
        <label className="text-base" htmlFor="meeting-link">Existing URL</label>
        <div className="input-wrapper">
          <input
            {...register('primaryStream', { validate: (v) => uriToStream(v) !== null, onChange: (e) => setPrimaryStreamKey(uriToStream(e.target.value)) })}
            type="text"
          />
          {
            primaryStreamData
              ? <img src={primaryStreamData.icon} alt={primaryStreamData.name} />
              : <div />
          }
          {formErrors.primaryStream && <div className="form-error">Requires valid meeting link</div>}
        </div>
      </div>
      <div />

      <div>
        <h3 className="text-base">Platforms</h3>
        <div className="platforms">
          {Object.entries(meetingTypes).map(([k, m]) => (
            <button
              className={clsx(
                'stream-button',
                primaryStreamKey === k && 'enabled primary',
                'enabled',
              )}
              key={m.name}
              type="button"
            >
              <img src={m.icon} alt={m.name} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
