import React from 'react';
import Button from '../components/Button/Button';

import PageLayout from '../components/Layout/PageLayout';

import pdfManual from '../res/manual.pdf';

export default function SettingsPage() {
  return (
    <PageLayout pageClassName="settings-page" title="Settings">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <Button href={pdfManual}>Download instructions</Button>
        </div>
        <div className="flex flex-col gap-3">
          <h2>User settings</h2>
          <Button>Reset password</Button>
        </div>
      </div>
    </PageLayout>
  );
}
