export function uriToStream(uri) {
  if (uri.includes('zoom.us')) {
    return 'zoom';
  }

  if (uri.includes('ciscospark') || uri.includes('webex')) {
    return 'webex';
  }

  if (uri.includes('meet.google.com')) {
    return 'meet';
  }

  if (uri.includes('teams.microsoft.com')) {
    return 'microsoft-teams';
  }

  return 'webex';
}

export const statusOrder = ['errored', 'running', 'scheduled', 'finished'];

export function sortMeetings(meetings) {
  return meetings?.sort((meeting1, meeting2) => statusOrder.indexOf(meeting1.status) - statusOrder.indexOf(meeting2.status));
}
