import React, { useState } from 'react';

import { login } from '../api';

import Button from '../components/Button/Button';
import Logo from '../components/Logo/Logo';
import PageLayout from '../components/Layout/PageLayout';
import Spinner from '../components/Spinner/Spinner';
import ErrorText from '../components/ErrorText/ErrorText';

import './login-page.scss';

export default function LoginPage({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginState, setloginState] = useState(null);

  const onLoginClick = async (e) => {
    e.preventDefault();

    setloginState('logging-in');

    try {
      const { token } = (await login({ email, password })).data;
      localStorage.setItem('token', token);
      onLogin();
    } catch {
      setloginState('error');
    }
  };

  const loggingIn = loginState === 'logging-in';

  return (
    <PageLayout includeMenu={false}>
      <div className="login-page">
        <form>
          <input disabled={loggingIn} type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input disabled={loggingIn} type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          {
              loginState === 'error' && (
              <ErrorText center>
                We couldn&apos;t sign you in. Please make sure you&apos;ve entered your credentials correctly.
              </ErrorText>
              )
          }
          {
            loggingIn ? <Spinner /> : <Button onClick={onLoginClick}>Sign in</Button>
          }
        </form>

        <div className="hero">
          <Logo />
          <h1 className="text-white">
            Let the technology work for
            {' '}
            <em className="colorize">you</em>
          </h1>
        </div>
      </div>
    </PageLayout>
  );
}
