import axios from 'axios';
import { uriToStream } from './util';

const apiRoot = process.env.REACT_APP_API_ROOT ?? 'http://localhost:3001/api/v1';

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return config;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers = config.headers ?? {};

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token;

  return config;
});

function apiUrl(url) {
  return `${apiRoot}/${url}`;
}

export async function getMeetings() {
  return axios.get(apiUrl('meetings'));
}

export function getMeeting(id) {
  return axios.get(apiUrl(`meetings/${id}`));
}

export function deleteMeeting(id) {
  return axios.delete(apiUrl(`meetings/${id}`));
}

export function createMeetings({ primaryStream, startTime, durationMinutes }) {
  const primaryStreamKey = uriToStream(primaryStream);

  return axios.post(apiUrl('meetings'), {
    streamServices: {
      [primaryStreamKey]: {
        uri: primaryStream,
      },
    },
    startTime,
    durationMinutes,
  });
}

export function login(loginData) {
  return axios.post(apiUrl('auth/login/jwt'), loginData);
}
